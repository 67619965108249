import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { FundDetailsComponent } from '../fund-details/fund-details.component';

@Component({
  selector: 'app-fund-details',
  templateUrl: './fund-details-action.component.html',
  styleUrls: ['./fund-details-action.component.css']
})
export class FundDetailsActionComponent implements OnInit, ICellRendererAngularComp {

  params: any;
  public fundDetailPopupModal: BsModalRef;

  constructor(public modalService: BsModalService) { }

  agInit(params: any): void {
    this.params = params;
  }

  public openModal() {
    const initialState = {
      param: this.params.data,
    };
    this.fundDetailPopupModal = this.modalService.show(FundDetailsComponent, Object.assign({ initialState }, { class: 'gray modal-lg' }));
  }

  refresh(): boolean {
    return false;
  }

  ngOnInit() {
  }

}
