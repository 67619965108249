import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  current_password="";
  new_password="";
  confirm_new_password="";

  constructor(private adminService: AdminService, private router: Router) { 
    
  }

  ngOnInit() {
  }

  changePassword = () => {

    if(this.new_password != this.confirm_new_password) {
      alert('New password and confirm new password must match!');
      return;
    }

    if(
      this.new_password.trim().length == 0 || 
      this.confirm_new_password.trim().length == 0 || 
      this.current_password.trim().length == 0 
    ) {
      alert('Password(s) should not be empty!');
      return;
    }

    let data =  {
      current_password: this.current_password,
      new_password: this.new_password
    }

    this.adminService.changePassword(data).subscribe(res => {
      if(res.success) {
        alert('Password has been changed successfully!');
        location.reload();
      }
      else {
        if(res.error == "invalid_password") {
          alert('Current password is invalid! Please enter correct password to continue.');
        }
      }
    })

  }

}
