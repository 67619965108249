import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FundTransferService } from '../../../services/fund-transfer.service';

@Component({
  selector: 'app-action',
  templateUrl: './fund-transfer-action.component.html',
  styleUrls: ['./fund-transfer-action.component.css']
})
export class FundTransferActionComponent implements OnInit, ICellRendererAngularComp {
  public params: any;
  confirm: any;
  showBtn: boolean = true;

  constructor(private fundTransferService: FundTransferService) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
    if (this.params.data.action == "APPROVED" || this.params.data.action == "REJECTED") {
      this.showBtn = false;
    }
  }

  refresh(): boolean {
    return false;
  }

  onApprove() {
    this.confirm = confirm("Are you sure you want to APPROVE?");
    if (this.confirm == true) {
      if (this.params.data != null) {
        let body = {
          action: "APPROVED",
          transfer_id: this.params.data.id
        }
        this.fundTransferService.action(body).subscribe(data => {
          if (data.success) {
            window.location.reload();
          }
          else {
            if (data.error == "already_closed") {
              alert('An action has already been taken!');
              return;
            }
          }
        })
      }
    }
  }

  onReject() {
    this.confirm = confirm("Are you sure you want to Reject?");
    if (this.confirm == true) {
      if (this.params.data != null) {
        let body = {
          action: "REJECTED",
          transfer_id: this.params.data.id
        }
        this.fundTransferService.action(body).subscribe(data => {
          if (data.success) {
            window.location.reload();
          }
          else {
            if (data.error == "already_closed") {
              alert('An action has already been taken!');
              return;
            }
          }
        })
      }
    }
  }

}
