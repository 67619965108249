import { Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { AuthService } from '../../services/auth.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email;
  password;

  constructor(
    private router: Router, 
    private adminService: AdminService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    if(this.authService.loggedIn()){
      this.router.navigate(['fundtransferdashboard']);
    }
  }

  login = () => {
    let userLogin = {
      email: this.email,
      password: this.password
    }

    this.adminService.login(userLogin).subscribe(data => {
      if(data.success) {
        localStorage.setItem("auth_token", data.token);
        localStorage.setItem("auth_admin", JSON.stringify(data.admin));
        this.router.navigate(['fundtransferdashboard']);
      }
      else {
        if(data.error == "invalid_password") {
          alert('Invalid Password!');
          return;
        }
        if(data.error == "invalid_email") {
          alert('Invalid Email!');
          return;
        }
        else {
          alert('Something went wrong!');
          return;
        }
      }
    });
  }

}
