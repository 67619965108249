import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FundTransferService {

  URL = environment.apiURL + '/users';

  constructor(private http: Http, private authService: AuthService) { }

  getFundTransfers() {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.authService.loadToken());
    return this.http.get(this.URL + '/fundtransfer', { headers: headers })
      .pipe(map(res => res.json()));
  }

  action(data) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.authService.loadToken());
    return this.http.post(this.URL + '/transferaction', data, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getPaypalTransfers() {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.authService.loadToken());
    return this.http.get(this.URL + '/pphistory/', { headers: headers })
        .pipe(map(res => res.json()));
  }

  paypalAction(data) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.authService.loadToken());
    return this.http.post(this.URL + '/ppaction', data, { headers: headers })
      .pipe(map(res => res.json()));
  }
  
}
