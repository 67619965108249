import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-fund-details',
  templateUrl: './fund-details.component.html',
  styleUrls: ['./fund-details.component.css']
})
export class FundDetailsComponent implements OnInit {

  param: any;

  constructor(public leaveDetailPopupModal: BsModalRef) { }

  ngOnInit() {
  }

  closeModal() {
    this.leaveDetailPopupModal.hide();
  }

}
