import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-action',
  templateUrl: './user-list-action.component.html',
  styleUrls: ['./user-list-action.component.css']
})
export class UserListActionComponent implements OnInit, ICellRendererAngularComp {
  public params: any;

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
  }

  getUserWallet() {
    this.router.navigate(['/userwallets/' + this.params.data.username + '/' + this.params.data.id]);
  }

  getUserWalletNew() {
    this.router.navigate(['/userwalletsnew/' + this.params.data.username + '/' + this.params.data.id]);
  }

  refresh(): boolean {
    return false;
  }

  userPorfile() {
    this.router.navigate(['/userprofile/' + this.params.data.id]);
  }

  getUserHistory() {
    this.router.navigate(['/tradinghistory/' + this.params.data.id]);
  }

  getOrders() {
    console.log('Calling get order function' , this.params.data.id)
    this.router.navigate(['/orders/' + this.params.data.id]);
  }

  activateAccount() {
    this.userService.activateAccount(this.params.data.id).subscribe(res => {
      if(res.success) {
        alert('User ID ' + this.params.data.id + ' successfully activated!');
        window.location.reload();
      } else {
        alert('Error while activating user ID ' + this.params.data.id);
      }
    })
  }

  deactivateAccount() {
    this.userService.deactivateAccount(this.params.data.id).subscribe(res => {
      if(res.success) {
        alert('User ID ' + this.params.data.id + ' successfully de-activated!');
        window.location.reload();
      } else {
        alert('Error while de-activating user ID ' + this.params.data.id);
      }
    })
  }

  userFeatures() {
    this.router.navigate(['/userfeatures/' + this.params.data.id]);
  }

}
