import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit {
  walletHash: string;
  symbols: any;
  positions = [];
  balance;
  RDSbalance;

  constructor(private activatedRoute: ActivatedRoute,
    private userService: UserService) {
    const id = this.activatedRoute.snapshot.params['walletId'];
    this.walletHash = this.activatedRoute.snapshot.params['walletHash'];
    this.getWalletHistory(id);
    // this.userService.fetchCapital(this.walletHash).subscribe(
    //   data => {
    //     if (data.success) {
    //       this.balance = data.balance;
    //     }
    // });
    this.userService.fetchRDSCapital(this.walletHash).subscribe(
      data => {
        if (data.success) {
          this.RDSbalance = data.balance;
        }
    });
  }

  ngOnInit() {
  }

  getWalletHistory(walletId: number) {
    this.userService.getWalletHistory(walletId).subscribe(data => {
      this.processData(data);
    });
  }

  async processData(data) {

    this.symbols = Array.from(new Set(data.map(data => data.symbol)).values());

    for (let i = 0; i < this.symbols.length; i++) {
      try {
        var position = await this.userService.fetchSymbolPosition(this.symbols[i], this.walletHash);
      }
      catch (e) {
        console.log('Error while fetching position! ', e);
      }

      this.positions.push({
        symbol: this.symbols[i],
        position: position
      });
    }
  }
}
