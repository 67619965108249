import { Component, OnInit } from '@angular/core';
import { FundTransferService } from '../../services/fund-transfer.service';
import { PaypalTranferActionComponent } from './action/paypal-tranfer-action.component';
import { DatePipe } from '../../../../node_modules/@angular/common';

@Component({
  selector: 'app-paypal-transfer',
  templateUrl: './paypal-transfer.component.html',
  styleUrls: ['./paypal-transfer.component.css']
})
export class PaypalTransferComponent implements OnInit {

  rowData: any;
  columnDefs;
  gridApi;
  frameworkComponents;
  paginationPageSize = 50;
  statusFilterComponent;
  filterGridId: number = 50;
  sortingOrder;

  constructor(
    private fundTransferService: FundTransferService
  ) { 
    this.getTransfers();
  }

  ngOnInit() {
    this.columnDefs = [
      {
        headerName: "Id",
        field: 'id',
        width: 75,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "User Id",
        field: 'user_id',
        width: 90,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Creation Time",
        field: 'create_time',
        width: 205,
        valueFormatter: function (params) {
          const datePipe = new DatePipe('en-US');
          return params.value === null ? '---' : datePipe.transform(params.value, "medium");
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Status",
        field: 'status',
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agTextColumnFilter",
        width: 120,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Paypal ID",
        field: 'pp_payment_id',
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agTextColumnFilter",
        width: 350,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Paypal Status",
        field: 'pp_state',
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agTextColumnFilter",
        width: 150,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Amount",
        field: 'amount',
        width: 105,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agNumberColumnFilter",
        sortable: true,
        resizable: true
      },
      {
        headerName: "Wallet Address",
        field: 'wallet_address',
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        width: 350,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Wallet Name",
        field: 'wallet_name',
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        width: 125,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Last Update Time",
        field: 'update_time',
        width: 205,
        valueFormatter: function (params) {
          const datePipe = new DatePipe('en-US');
          return params.value === null ? '---' : datePipe.transform(params.value, "medium");
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Approve/Reject",
        cellRenderer: "actionComponent",
        width: 180,
        autoHeight: true,
        resizable: true
      },
     
      
    ];
    this.sortingOrder = ["desc", "asc", null];
    this.frameworkComponents = {
      actionComponent: PaypalTranferActionComponent,
    };
  }

  getTransfers = () => {
    this.fundTransferService.getPaypalTransfers().subscribe(data => {
      this.rowData = data;
      console.log(data);
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.sortByIdAsc();
  }

  sortByIdAsc() {
    var sort = [
      {
        colId: "id",
        sort: "desc"
      }
    ];
    this.gridApi.setSortModel(sort);
  }

  filterEvent() {
    this.gridApi.paginationSetPageSize(this.filterGridId)
  }

  getClosedStatusRecords() {
    this.statusFilterComponent = this.gridApi.getFilterInstance("status");
    this.statusFilterComponent.setModel({
      type: "notEqual",
      filter: "PENDING"
    });
    this.gridApi.onFilterChanged();
  }

  getOpenStatusRecords() {
    this.statusFilterComponent = this.gridApi.getFilterInstance("status");
    this.statusFilterComponent.setModel({
      type: "equals",
      filter: "PENDING"
    });
    this.gridApi.onFilterChanged();
  }

  clearStatusFilter() {
    this.statusFilterComponent = this.gridApi.getFilterInstance("status");
    this.statusFilterComponent.setModel(null);
    this.gridApi.onFilterChanged();
  }


  

}
