import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.css']
})
export class ChangeEmailComponent implements OnInit {

  newemail = "";
  newemailc = "";

  constructor(private adminService: AdminService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

  changeEmail(){

    if(this.newemail != this.newemailc) {
      alert('New email and confirm new email must match!');
      return;
    }

    if(this.newemail.trim().length == 0 || this.newemailc.trim().length == 0) {
      alert('Email should not be empty!');
      return;
    }

    const regex=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!regex.test(this.newemail)) {
      alert('Enter a valid email address');
      return;
    }

    this.adminService.changeEmail(this.newemail).subscribe(res => {
      if(res.success) {
        alert('Email changed successfully! Please login with your new email.');
        this.authService.logout();
        this.router.navigate(['/login']);
      }
      else {
        if(res.error == "duplicate_email") {
          alert('Email ID already in use! Please enter different email to add.');
          return;
        } else {
          alert('Something went wrong! Please try again.');
          return;
        }
      }
    });
  }

}
