import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { UserListActionComponent } from './action/user-list-action.component';
import { DatePipe } from '../../../../node_modules/@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  public params: any;
  rowData: any;
  columnDefs;
  gridApi;
  frameworkComponents;
  paginationPageSize;
  emailFilterComponent;
  isStatementPendingFilterActive: boolean = false;

  constructor(private router: Router, private userService: UserService) {
    this.getUserList();
  }

  getUserList = () => {
    this.userService.getUserList().subscribe(data => {
      this.rowData = data;
    })
  }

  ngOnInit() {
    this.columnDefs = [
      {
        headerName: "User ID",
        field: 'id',
        width: 100,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Email",
        field: 'email',
        width: 300,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Username",
        field: 'username',
        width: 200,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Creation Time",
        field: 'createtime',
        width: 220,
        filter: true,
        sortable: true,
        resizable: true,
        valueFormatter: function (params) {
          const datePipe = new DatePipe('en-US');
          return params.value === null ? '---' : datePipe.transform(params.value, "medium");
        },
      },
      {
        headerName: "Status",
        field: 'islocked',
        width: 130,
        valueFormatter: function (params) {
          console.log(params)
          return params.value === true ? 'IN-ACTIVE' : 'ACTIVE';
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      // {
      //   headerName: "Deleted",
      //   field: 'deleted',
      //   width: 154,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value;
      //   },
      //   filter: true,
      //   sortable: true,
      //   resizable: true
      // },
      // {
      //   headerName: "Deletion Time",
      //   field: 'deletetime',
      //   width: 220,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value;
      //   },
      //   filter: true,
      //   sortable: true,
      //   resizable: true
      // },
      {
        headerName: "Email Verification",
        field: 'isverified',
        width: 150,
        // valueFormatter: function (params) {
        //   return params.value === true ? 'YES' : 'NO';
        // },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "KYC Submission",
        field: 'kyc',
        width: 150,
        // valueFormatter: function (params) {
        //   return params.value === true ? 'YES' : 'NO';
        // },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "",
        width: 400,
        cellRenderer: "actionComponent",
        autoHeight: true,
        filter: false,
        resizable: true
      }
    ];
    this.frameworkComponents = {
      actionComponent: UserListActionComponent
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // Method to handle row click
  onRowClicked(event) {
    if (this.isStatementPendingFilterActive) {  // Check if "Statement Pending" filter is active
      const selectedUserId = event.data.u_id;
      console.log('into row click:' , selectedUserId)
      this.getUserHistory(selectedUserId);
    }
  }

  getUserHistory(userId: number) {
    this.router.navigate(['/monthlytradinghistory/' + userId]);
  }

  onExportToCSV() {
    var params = {
      fileName: "user-list",
      columnSeparator: ",",
      columnKeys: ['id', 'email', 'username', 'createtime']
    };
    this.gridApi.exportDataAsCsv(params);
  }

  filterEmailVerification(bool) {
    this.isStatementPendingFilterActive = false;

    this.emailFilterComponent = this.gridApi.getFilterInstance("isverified");
    this.emailFilterComponent.setModel({
      type: "startsWith",
      filter: bool
    });
    this.gridApi.onFilterChanged();
  }

  filterKYC(bool) {
    this.isStatementPendingFilterActive = false;
    this.emailFilterComponent = this.gridApi.getFilterInstance("kyc");
    this.emailFilterComponent.setModel({
      type: "startsWith",
      filter: bool
    });
    this.gridApi.onFilterChanged();
  }

  getRowStyle(params) {
    if (this.isStatementPendingFilterActive) {
      return { cursor: 'pointer' };
    } else {
      return {};
    }
  }

  filterStatementPending() {
    console.log('Inside FSP:')
    this.userService.getLatestHistoryForCurrentMonth().subscribe(data => {
      this.rowData = data;
      console.log('rowData', data)
      // Update the column definitions to only show the required columns
      this.columnDefs = [
        { headerName: "User ID", field: 'u_id', width: 200 },
        { headerName: "Username", field: 'username', width: 400 },
        { headerName: "Email", field: 'u_email', width: 400 },
        {
          headerName: "Last Activity", field: 'createtime', width: 400, valueFormatter: (params) => {
            const datePipe = new DatePipe('en-US');
            return datePipe.transform(params.value, "medium");
          }
        },
      ];
      this.gridApi.setColumnDefs(this.columnDefs);
    });
    this.isStatementPendingFilterActive = true;
  }

}
