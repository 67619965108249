import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-trading-history',
  templateUrl: './trading-history.component.html',
  styleUrls: ['./trading-history.component.css']
})
export class TradingHistoryComponent implements OnInit {

  rowData: any;
  columnDefs;
  gridApi;
  frameworkComponents;
  paginationPageSize;
  filterGridId: number = 250;

  constructor(private activatedRoute: ActivatedRoute,
    private userService: UserService) {
    const id = this.activatedRoute.snapshot.params['userId'];
    this.getUserHistory(id);
    this.paginationPageSize = 250;
  }

  filterEvent() {
    this.gridApi.paginationSetPageSize(this.filterGridId)
  }

  ngOnInit() {
    this.columnDefs = [
      {
        headerName: "Id",
        field: 'id',
        width: 70,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "User Id",
        field: 'user_id',
        width: 90,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Create Time",
        field: 'createtime',
        width: 210,
        valueFormatter: function (params) {
          const datePipe = new DatePipe('en-US');
          return params.value === null ? '---' : datePipe.transform(params.value, "medium");
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Symbol",
        field: 'symbol',
        width: 100,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Exchange",
        field: 'exchange',
        width: 125,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "AI Status",
        field: 'ai_status',
        width: 125,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Average Fill Price",
        field: 'avg_fill_price',
        width: 190,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agNumberColumnFilter",
        sortable: true,
        resizable: true
      },
      {
        headerName: "Action",
        field: 'action',
        width: 154,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Capital",
        field: 'capital',
        width: 150,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agNumberColumnFilter",
        sortable: true,
        resizable: true
      },
      {
        headerName: "Fees",
        field: 'fees',
        width: 75,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agNumberColumnFilter",
        sortable: true,
        resizable: true
      },
      {
        headerName: "Limit Price",
        field: 'limit_price',
        width: 115,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agNumberColumnFilter",
        sortable: true,
        resizable: true
      },
      {
        headerName: "Order Type",
        field: 'order_type',
        width: 120,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Status",
        field: 'status',
        width: 100,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Volume",
        field: 'volume',
        width: 100,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agNumberColumnFilter",
        sortable: true,
        resizable: true
      },
      {
        headerName: "Wallet Name",
        field: 'wallet_name',
        width: 150,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
    ];
  }

  getUserHistory(userId: number) {
    this.userService.getUserHistory(userId).subscribe(
      data => {
        this.rowData = data;
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

}
