import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Workbook } from 'exceljs';
import { DatePipe } from '@angular/common';
import * as fs from 'file-saver';
import * as logoFile from '../report/logo.js';

@Component({
  selector: 'app-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.css']
})
export class GenerateReportComponent implements OnInit {

  history = [];
  pendingOrders = {};
  type: number = 1;
  daterange;

  constructor(private userService: UserService) { 
    console.log('GenerateReportComponent Instantiated');
  }

  async ngOnInit() {
    try{
      console.log('ngOnInit called');
      this.userService.getHistory().subscribe(data => {
        console.log('All History:', data);
        this.history = data;
        console.log('This History', this.history)
      })

    } catch (error) {
      console.error('Error fetching the history' , error)
    }

  }

  generate() {
    // validation
    if(!this.type) {
      return alert('Select report type to continue');
    }
    if(this.type == 2 && !this.daterange) {
      return alert('Select Date Range to continue');
    } 

    // generate report
    this.generateReport(this.type);
  }


  generateReport(type) {


    if(type == 1) {

      // -----------------------------------------------------------------------------
      //                              CREATE EXCEL
      // -----------------------------------------------------------------------------
      if (!this.history || this.history.length === 0) {
        alert("Response from the backend hasn't arrived yet. Please wait a few seconds and try again.");
        return;
      }

      // Worksheet obj
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('User Position Report Data');

      // Logo
      let logo = workbook.addImage({
        base64: logoFile.logoBase64,
        extension: 'png',
      });
      worksheet.addImage(logo, 'A1:E5');
      worksheet.mergeCells('A1:E5');

      // Title
      let cell_title = worksheet.getCell('B7');
      cell_title.value = 'User Position Statement: ';
      worksheet.mergeCells('B7:D7');

      // Columns Header
      worksheet.columns = [
        { header: "",	key: 'userid', width: 15},
        { header: "",	key: 'email', width: 60},
        { header: "", key: 'symbol', width: 15},
        { header: "",	key: 'position', width: 15}
      ];
      let headerRow = worksheet.addRow({
        userid: "User ID",
        email: "Email",
        symbol: "Symbol",
        position: "Position"
      });
      headerRow.height = 25;
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF2CC' },
          bgColor: { argb: 'FFF2CC' }
        }
        cell.border = { top: { style: 'medium', color: {argb:'DEDEDE'} }, left: { style: 'medium', color: {argb:'DEDEDE'} }, bottom: { style: 'medium', color: {argb:'DEDEDE'} }, right: { style: 'medium', color: {argb:'DEDEDE'} } }
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
      });
    

      // -----------------------------------------------------------------------------
      //                              PROCESS DATA
      // -----------------------------------------------------------------------------

      console.log('This.History in' , this.history)
      let data = this.history;

      console.log('History:' , data)
      // get the user ids set
      let uids =  Array.from(new Set(data.map(data => data.u_id)).values());

      for(let i = 0; i < uids.length; i++) {
        
        // filter history by user id
        let user_history = data.filter((item: any) => {
          return item.u_id == uids[i]
        });

        // console.log(user_history);

        // get symbol set from user history
        let symbols = Array.from(new Set(user_history.map(user_history => user_history.symbol)).values());
        // console.log(uids[i], symbols);

        for(let k = 0; k < symbols.length; k++ ) {

          // Filter MKT order by symbol and sort desc
          let orders = user_history.filter(row => row.symbol === symbols[k] && row.action && row.volume).sort((a, b) => {
            return (b.id - a.id); 
          });
    
          // console.log(uids[i], symbols[k], 'history:', orders);
    
          let netPosition = 0;
          if(orders.length > 0) {
            for(let j = 0; j < orders.length; j++) {
              if(orders[j].action == "BUY") {
                netPosition = netPosition + orders[j].volume;
              }
              if(orders[j].action == "SELL") {
                netPosition = netPosition - orders[j].volume;
              }
            }

            if(netPosition !== 0) {
              // add row
              worksheet.addRow({
                userid: uids[i],
                email: orders[0].u_email,
                symbol: symbols[k],
                position: netPosition
              });
            }
          }
          
          console.log(uids[i], symbols[k], 'position:', netPosition);
        }
      
      }
      
      console.log('Data:' , data)
      
      // -----------------------------------------------------------------------------
      //                              GENERATE EXCEL
      // -----------------------------------------------------------------------------

      //Generate & Save Excel File
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'User Position Statement - '+ new Date().toString() +'.xlsx');
      })
      

    }

    if(type == 2) {

      // -----------------------------------------------------------------------------
      //                              CREATE EXCEL
      // -----------------------------------------------------------------------------

      // Worksheet obj
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('AI Advice Report Data');

      // Logo
      let logo = workbook.addImage({
        base64: logoFile.logoBase64,
        extension: 'png',
      });
      worksheet.addImage(logo, 'A1:E5');
      worksheet.mergeCells('A1:E5');

      // Title
      let cell_title = worksheet.getCell('B7');
      cell_title.value = 'AI Advice Statement: ';
      worksheet.mergeCells('B7:D7');

      // Columns Header
      worksheet.columns = [
        { header: "",	key: 'userid', width: 15},
        { header: "",	key: 'email', width: 60},
        { header: "", key: 'symbol', width: 15},
        { header: "",	key: 'ai_status', width: 15},
        { header: "",	key: 'date', width: 15},
        { header: "",	key: 'fees', width: 15},
      ];
      let headerRow = worksheet.addRow({
        userid: "User ID",
        email: "Email",
        symbol: "Symbol",
        ai_status: "AI Advice",
        date: "Date",
        fees: "Fees"
      });
      headerRow.height = 25;
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF2CC' },
          bgColor: { argb: 'FFF2CC' }
        }
        cell.border = { top: { style: 'medium', color: {argb:'DEDEDE'} }, left: { style: 'medium', color: {argb:'DEDEDE'} }, bottom: { style: 'medium', color: {argb:'DEDEDE'} }, right: { style: 'medium', color: {argb:'DEDEDE'} } }
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
      });


      // -----------------------------------------------------------------------------
      //                              PROCESS DATA
      // -----------------------------------------------------------------------------

      let fromDate = this.daterange[0];
      let toDate = this.daterange[1];

      // filter the data to date range
      let data = this.history.filter((item: any) => {
        return new Date(item.createtime).getTime() >= fromDate.getTime() &&
              new Date(item.createtime).getTime() <= toDate.getTime();
      });

      // get the user ids set
      let uids =  Array.from(new Set(data.map(data => data.u_id)).values());

      for(let i = 0; i < uids.length; i++) {
        
        // filter history by user id and get the ai advice commission
        let user_history = data.filter((item: any) => {
          return item.u_id == uids[i] && item.ai_status && item.fees
        });

        console.log(user_history);

        if(user_history.length) {
          for(let j = 0; j < user_history.length; j++) {
            worksheet.addRow({
              userid: user_history[j].u_id,
              email: user_history[j].u_email,
              symbol: user_history[j].symbol,
              ai_status: user_history[j].ai_status,
              date: user_history[j].createtime,
              fees: user_history[j].fees
            });
          }

          // add empty row
          worksheet.addRow({});
        }

      }


      //Generate & Save Excel File
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'User AI Advice Statement - '+ new Date().toString() +'.xlsx');
      })
   
    }

    if(type == 3) {

      this.userService.getAllPendingOrders().subscribe(async (res: Object) => {

        // -----------------------------------------------------------------------------
        //                              CREATE EXCEL
        // -----------------------------------------------------------------------------

        // Worksheet obj
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Pending Orders Report Data');

        // Logo
        let logo = workbook.addImage({
          base64: logoFile.logoBase64,
          extension: 'png',
        });
        worksheet.addImage(logo, 'A1:E5');
        worksheet.mergeCells('A1:E5');

        // Title
        let cell_title = worksheet.getCell('B7');
        cell_title.value = 'User Pending Orders ';
        worksheet.mergeCells('B7:D7');

        // Columns Header
        worksheet.columns = [
          { header: "",	key: 'userid', width: 15},
          { header: "", key: 'stock_symbol', width: 15},
          { header: "", key: 'exchange', width: 15},
          { header: "",	key: 'type', width: 15},
          { header: "",	key: 'date', width: 30},
          { header: "",	key: 'status', width: 15},
          { header: "",	key: 'volume', width: 15},
          { header: "",	key: 'capital', width: 15},
          { header: "",	key: 'walletName', width: 15},
          { header: "",	key: 'walletAddress', width: 45},
        ];
        let headerRow = worksheet.addRow({
          userid: "User ID",
          stock_symbol: "Symbol",
          exchange: "Exchange",
          type: "Type",
          date: "Date",
          status: "Status",
          volume: "Volume",
          capital: "Capital",
          walletName: "Wallet Name",
          walletAddress: "Wallet Address"
        });
        headerRow.height = 25;
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF2CC' },
            bgColor: { argb: 'FFF2CC' }
          }
          cell.border = { top: { style: 'medium', color: {argb:'DEDEDE'} }, left: { style: 'medium', color: {argb:'DEDEDE'} }, bottom: { style: 'medium', color: {argb:'DEDEDE'} }, right: { style: 'medium', color: {argb:'DEDEDE'} } }
          cell.alignment = { vertical: 'middle', horizontal: 'center' }
        });


        // -----------------------------------------------------------------------------
        //                              PROCESS DATA
        // -----------------------------------------------------------------------------

        for(let user_id in res) {
          if(res.hasOwnProperty(user_id)) {
            let orders = res[user_id];

            for (let i = 0; i < orders.length; i++) {
              worksheet.addRow({
                userid: user_id,
                stock_symbol: orders[i].data.stock_symbol,
                exchange: orders[i].data.exchange,
                type: orders[i].data.type,
                date: new Date(orders[i].timestamp).toLocaleString(),
                status: orders[i].sent ? 'INPROGRESS' : 'WAITING',
                volume: orders[i].data.volume,
                capital: orders[i].data.capital,
                walletName: orders[i].data.walletName,
                walletAddress: orders[i].data.walletAddress
              });
            }

            // add empty row
            worksheet.addRow({});
          }
        }


        // //Generate & Save Excel File
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, 'User Pending Orders Statement - '+ new Date().toString() +'.xlsx');
        })


      }, err => {
        alert('Error while fetching user pending order information!');
      })

    }

    if(type == 4) {
      this.userService.getAllWallet().subscribe(async (res: Object) => {

        // -----------------------------------------------------------------------------
        //                              CREATE EXCEL
        // -----------------------------------------------------------------------------

        console.log('Response:' , res)


        // Worksheet obj
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Pending Wallets Report Data');

        // Logo
        let logo = workbook.addImage({
          base64: logoFile.logoBase64,
          extension: 'png',
        });
        worksheet.addImage(logo, 'A1:E5');
        worksheet.mergeCells('A1:E5');

        // Title
        let cell_title = worksheet.getCell('B7');
        cell_title.value = 'User Pending Wallet ';
        worksheet.mergeCells('B7:D7');

        // Columns Header
        worksheet.columns = [
          { header: "",	key: 'userid', width: 15},
          { header: "", key: 'email', width: 30},
          { header: "", key: 'wallet_name', width: 15},
          { header: "",	key: 'setup', width: 15},
          { header: "",	key: 'balance_by_admin', width: 30},
          { header: "",	key: 'specific_date', width: 30},
          

        ];
        let headerRow = worksheet.addRow({
          userid: "User ID",
          email: "Email",
          wallet_name: "Wallet Name",
          setup: "Set up",
          balance_by_admin: "Balance by Admin",
          specific_date: "Specific date",
        });
        headerRow.height = 25;
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF2CC' },
            bgColor: { argb: 'FFF2CC' }
          }
          cell.border = { top: { style: 'medium', color: {argb:'DEDEDE'} }, left: { style: 'medium', color: {argb:'DEDEDE'} }, bottom: { style: 'medium', color: {argb:'DEDEDE'} }, right: { style: 'medium', color: {argb:'DEDEDE'} } }
          cell.alignment = { vertical: 'middle', horizontal: 'center' }
        });


        // -----------------------------------------------------------------------------
        //                              PROCESS DATA
        // -----------------------------------------------------------------------------

        for(let user_id in res) {
          if(res.hasOwnProperty(user_id)) {
            let wallets = res[user_id];
            console.log('Wallets' , wallets)

              console.log('Inde')
              worksheet.addRow({
                userid: wallets.user_id,
                email: wallets.email,
                wallet_name: wallets.wallet_name,                
                setup: wallets.balance_by_admin ? "Set": 'Not set',
                balance_by_admin: wallets.balance_by_admin,
                specific_date: wallets.end_date && wallets.end_date !== "" ? new Date(wallets.end_date).toLocaleString() : '',
                //status: orders[i].sent ? 'INPROGRESS' : 'WAITING',

              });
            // add empty row
            worksheet.addRow({});
          }
        }


        // //Generate & Save Excel File
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, 'User Pending Wallets - '+ new Date().toString() +'.xlsx');
        })


      }, err => {
        alert('Error while fetching user pending wallets information!');
      })
    }
    
  }



}
