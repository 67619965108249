import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  auth_token;

  constructor() { }

  storeUserData(token) {
    localStorage.setItem('auth_token', token);
    this.auth_token = token;
  }

  loadToken() {
    this.auth_token = localStorage.getItem('auth_token');
    return this.auth_token;
  }

  loggedIn() {
    this.loadToken();
    return !(new JwtHelperService().isTokenExpired(this.auth_token));
  }

  logout() {
    this.auth_token = null;
    localStorage.removeItem("auth_token");
  }
}
