import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  URL = environment.apiURL + '/admins';

  constructor(private http: Http, private authService: AuthService) { }


  login(user) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.URL + '/login', user, { headers: headers })
      .pipe(map(res => res.json()));
  }

  addAdmin(data) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.authService.loadToken());
    return this.http.post(this.URL + '/register', data, { headers: headers })
      .pipe(map(res => res.json()));
  }

  changePassword(data) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.authService.loadToken());
    return this.http.post(this.URL + '/pwdupdate', data, { headers: headers })
      .pipe(map(res => res.json()));
  }

  changeEmail(email) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.authService.loadToken());
    return this.http.post(this.URL + '/emailupdate', { email: email }, { headers: headers })
      .pipe(map(res => res.json()));
  }

  getUserFeatures(userid) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.authService.loadToken());
    return this.http.get(this.URL + `/user/${userid}/features/`, { headers: headers })
      .pipe(map(res => res.json()));
  }

  updateUserFeatures(data) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', this.authService.loadToken());
    return this.http.post(this.URL + '/user/features', data, { headers: headers })
      .pipe(map(res => res.json()));
  }

}
