import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FundTransferService } from '../../../services/fund-transfer.service';

@Component({
  selector: 'app-paypal-tranfer-action',
  templateUrl: './paypal-tranfer-action.component.html',
  styleUrls: ['./paypal-tranfer-action.component.css']
})
export class PaypalTranferActionComponent implements OnInit {

  public params: any;
  confirm: any;
  showBtn: boolean = true;

  constructor(private fundTransferService: FundTransferService) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
    if (this.params.data.status != "PENDING") {
      this.showBtn = false;
    }
  }

  onAction(action) {
    this.confirm = confirm(`Are you sure you want it to be ${action} ?`);
    if (this.confirm == true) {
      if (this.params.data != null) {
        let body = {
          status: action,
          ppId: this.params.data.id
        }
        this.fundTransferService.paypalAction(body).subscribe(data => {
          if (data.success) {
            window.location.reload();
          }
          else {
            if (data.error == "already_closed") {
              alert('An action has already been taken!');
              return;
            }
          }
        })
      }
    }
  }
}
