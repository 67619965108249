import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FundTransferService } from '../../services/fund-transfer.service'
import { from } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  transfers = [];

  constructor( private fundTransferService: FundTransferService ) { }

  ngOnInit() {
    this.getTransfers();
  }

  getTransfers = () => {
    this.fundTransferService.getFundTransfers().subscribe(data => {
      console.log(data);
      this.transfers = data;
    })
  }

  action = (action, transfer_id) => {
    let body = {
      action: action,
      transfer_id: transfer_id
    }

    this.fundTransferService.action(body).subscribe(data => {
      console.log(data);
      if(data.success) {
        alert('Successful');
        window.location.reload();
      }    
    })
  }

}
