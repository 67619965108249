import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css']
})
export class AddAdminComponent implements OnInit {

  email = "";
  password = "";
  cpassword = "";

  constructor(private adminService: AdminService, private router: Router) { 
  }

  ngOnInit() {
  }

  addAdmin() {
    if(
      this.email.trim().length == 0 ||
      this.password.trim().length == 0 ||
      this.cpassword.trim().length == 0
    ) {
      alert('Please fill all required information to continue.');
      return;
    }

    const regex=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!regex.test(this.email)) {
      alert('Enter valid email address');
      return;
    }

    if(this.password != this.cpassword) {
      alert('Password and confirm password must match.');
      return;
    }
  
    let admin = {
      password        : this.password,
      email           : this.email 
    }

    this.adminService.addAdmin(admin).subscribe(res => {
      if(res.success) {
        alert('Admin added successfully.');
        location.reload();
        return;
      } else {
        if(res.error == "duplicate_email") {
          alert('Email ID already in use! Please enter different email to add.');
          return;
        } else {
          alert('Something went wrong! Please try again.');
          return;
        }
      }
    });
  }

}
