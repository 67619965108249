import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-user-features',
  templateUrl: './user-features.component.html',
  styleUrls: ['./user-features.component.css']
})
export class UserFeaturesComponent implements OnInit {

  userId;
  features = [];

  constructor(private activatedRoute: ActivatedRoute, private adminService: AdminService) { }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.params['userId'];
    this.loadUserFeatures(this.userId);
  }

  loadUserFeatures = (userId) => {
    this.adminService.getUserFeatures(userId).subscribe(res => {
      if (res.success) {
        this.features = res.result;
        // TODO - make dynamic by integrating GET featurelist API
        if(!this.features.length) {
          // if no feature rows available - show all feature rows as ACTIVE by default to show the action buttons
          this.features.push({
            feature_id: 1,
            label: 'AUTO TRADING',
            status: true
          })
        }
      } else {
        alert('Error while fetching the user features list');
      }
    });
  }

  updateUserFeature = (featureId, status) => {
    const data = {
      userid: this.userId,
      features: [{
        id: featureId,
        status: status
      }]
    }

    this.adminService.updateUserFeatures(data).subscribe((res) => {
      if (res.success) {
        alert(`Feature successfully ${(status ? 'Activated' : 'Disabled')}`);
        window.location.reload();
      } else {
        alert('Error while updating the feature state');
      }
    })
  }

}
