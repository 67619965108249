import { Component, OnInit } from '@angular/core';
import { FundTransferService } from '../../services/fund-transfer.service';
import { FundTransferActionComponent } from './action/fund-transfer-action.component';
import { FundDetailsActionComponent } from './fund-details-action/fund-details-action.component';
import { DatePipe } from '../../../../node_modules/@angular/common';

@Component({
  selector: 'app-fund-transfer-dashboard',
  templateUrl: './fund-transfer-dashboard.component.html',
  styleUrls: ['./fund-transfer-dashboard.component.css']
})
export class FundTransferDashboardComponent implements OnInit {

  rowData: any;
  columnDefs;
  gridApi;
  frameworkComponents;
  paginationPageSize;
  statusFilterComponent;
  filterGridId: number = 50;
  sortingOrder;
  constructor(private fundTransferService: FundTransferService) {
    this.getTransfers();
    this.paginationPageSize = 50;
  }

  getTransfers = () => {
    this.fundTransferService.getFundTransfers().subscribe(data => {
      this.rowData = data;
      console.log(data);
    })
  }

  ngOnInit() {
    this.columnDefs = [
      {
        headerName: "Id",
        field: 'id',
        width: 75,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "User Id",
        field: 'user_id',
        width: 90,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Type",
        field: 'type',
        width: 125,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Amount",
        field: 'amount',
        width: 105,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agNumberColumnFilter",
        sortable: true,
        resizable: true
      },
      {
        headerName: "Wallet Address",
        field: 'wallet',
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        width: 350,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Wallet Name",
        field: 'wallet_name',
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        width: 125,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Creation Time",
        field: 'createtime',
        width: 205,
        valueFormatter: function (params) {
          const datePipe = new DatePipe('en-US');
          return params.value === null ? '---' : datePipe.transform(params.value, "medium");
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Action",
        field: 'action',
        width: 105,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Status",
        field: 'status',
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: "agTextColumnFilter",
        width: 90,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Details",
        cellRenderer: "detailsComponent",
        width: 88,
        autoHeight: true,
        filter: false,
        sortable: false,
        resizable: true
      },
      {
        headerName: "Approve/Reject",
        cellRenderer: "actionComponent",
        width: 180,
        autoHeight: true,
        resizable: true
      },
      // {
      //   headerName: "ABA Number",
      //   field: 'abanumber',
      //   width: 125,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },
      // {
      //   headerName: "Bank Address",
      //   field: 'bank_addr',
      //   width: 120,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },
      // {
      //   headerName: "Bank Country",
      //   field: 'bank_country',
      //   width: 100,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },
      // {
      //   headerName: "Bank Name",
      //   field: 'bank_name',
      //   width: 100,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },
      // {
      //   headerName: "Bank Address",
      //   field: 'bankacc_addr',
      //   width: 100,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },
      // {
      //   headerName: "Bank Account Number",
      //   field: 'bankacc_num',
      //   width: 150,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },
      // {
      //   headerName: "Beneficiary Address",
      //   field: 'beneficiary_addr',
      //   width: 150,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },
      // {
      //   headerName: "Benifit To",
      //   field: 'benifit_to',
      //   width: 100,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },
      
      // {
      //   headerName: "Currency",
      //   field: 'currency',
      //   width : 100
      // },
      // {
      //   headerName: "Others",
      //   field: 'others',
      //   width: 70,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },
      // {
      //   headerName: "Statement Number",
      //   field: 'statement_num',
      //   width: 130,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },     
      // {
      //   headerName: "Swift Bic-code",
      //   field: 'swiftbiccode',
      //   width: 110,
      //   valueFormatter: function (params) {
      //     return params.value === null ? '---' : params.value; 
      //   },
      // },  
      
    ];
    this.sortingOrder = ["desc", "asc", null];
    this.frameworkComponents = {
      actionComponent: FundTransferActionComponent,
      detailsComponent: FundDetailsActionComponent,
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.sortByIdAsc();
  }

  sortByIdAsc() {
    var sort = [
      {
        colId: "id",
        sort: "desc"
      }
    ];
    this.gridApi.setSortModel(sort);
  }

  filterEvent() {
    this.gridApi.paginationSetPageSize(this.filterGridId)
  }

  getClosedStatusRecords() {
    this.statusFilterComponent = this.gridApi.getFilterInstance("status");
    this.statusFilterComponent.setModel({
      type: "startsWith",
      filter: "CLOSE"
    });
    this.gridApi.onFilterChanged();
  }

  getOpenStatusRecords() {
    this.statusFilterComponent = this.gridApi.getFilterInstance("status");
    this.statusFilterComponent.setModel({
      type: "startsWith",
      filter: "OPEN"
    });
    this.gridApi.onFilterChanged();
  }

  clearStatusFilter() {
    this.statusFilterComponent = this.gridApi.getFilterInstance("status");
    this.statusFilterComponent.setModel(null);
    this.gridApi.onFilterChanged();
  }

}
