import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { PendingOrdersActionComponent } from './action/pending-orders-action.component';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-user-pending-orders',
  templateUrl: './user-pending-orders.component.html',
  styleUrls: ['./user-pending-orders.component.css']
})
export class UserPendingOrdersComponent implements OnInit {

  rowData: any;
  columnDefs;
  gridApi;
  frameworkComponents;
  paginationPageSize;
  userId;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) { 
    this.userId = this.activatedRoute.snapshot.params['userId'];

    this.userService.getPendingOrders(this.userId).subscribe(orders => {
      console.log('orders:', orders);
      this.rowData = orders;
    })
  }

  ngOnInit() {   

    this.columnDefs = [
      {
        headerName: "Order No.",
        field: 'index',
        width: 100,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Date",
        field: 'timestamp',
        width: 220,
        filter: true,
        sortable: true,
        resizable: true,
        valueFormatter: function (params) {
          const datePipe = new DatePipe('en-US');
          return params.value === null ? '---' : datePipe.transform(params.value, "medium");
        },
      },
      {
        headerName: "Status",
        field: 'sent',
        width: 130,
        valueFormatter: function (params) {
          return params.value === true ? 'INPROGRESS' : 'WAITING';
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Symbol",
        field: 'data.stock_symbol',
        width: 100,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Exchange",
        field: 'data.exchange',
        width: 120,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Type",
        field: 'data.type',
        width: 100,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Volume",
        field: 'data.volume',
        width: 100,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Capital",
        field: 'data.capital',
        width: 100,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Wallet Name",
        field: 'data.walletName',
        width: 100,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Wallet Address",
        field: 'data.walletAddress',
        width: 300,
        valueFormatter: function (params) {
          return params.value === null ? '---' : params.value;
        },
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Action",
        width: 100,
        cellRenderer: "actionComponent",
        autoHeight: true,
        filter: false,
        resizable: true
      }
    ];
    this.frameworkComponents = {
      actionComponent: PendingOrdersActionComponent
    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  onExportToCSV() {
    var params = {
      fileName: "pending-orders-user-" + this.userId,
      columnSeparator: ",",
      columnKeys: ['index', 'timestamp', 'data.stock_symbol', 'data.exchange', 'data.type', 'data.volume', 'data.capital', 'data.walletName']
    };
    this.gridApi.exportDataAsCsv(params);
  }

}
