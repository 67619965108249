import { Component, OnInit, Input } from '@angular/core';
import { Workbook } from 'exceljs';
import { DatePipe } from '@angular/common';
import * as fs from 'file-saver';
import * as logoFile from './logo.js';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  @Input() data: any[];

  daterange;
  userId;
  extra_charge;

  title = 'Trading Report';
  header = [
    { header: "", key: 'symbol', width: 15 },
    { header: "", key: 'createtime', width: 15 },
    { header: "", key: 'volume', width: 15 },
    { header: "", key: 'price', width: 15 },
    { header: "", key: 'ai_status', width: 15 },
    { header: "", key: 'fees', width: 15 },
    { header: "", key: 'extra_charge', width: 25 },
    { header: "", key: 'basis', width: 25 },
    { header: "", key: 'RPL', width: 30 },
    { header: "", key: 'wallet_name', width: 30 }
  ];

  constructor(private datePipe: DatePipe, private activatedRoute: ActivatedRoute) { 
    this.userId = this.activatedRoute.snapshot.params['userId'];
    this.extra_charge = 1.86
  }


  ngOnInit() {
  }

  generateAndDownload() {

    if (!this.daterange) {
      alert('Select Dates to continue');
      return;
    }

    // validate extra charge
    if (String(this.extra_charge) == "undefined" || isNaN(this.extra_charge)) {
      alert('Enter valid extra charge');
      return;
    }

    // convert extra charge to float
    this.extra_charge = parseFloat(this.extra_charge);

    // Worksheet obj
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Trading Report Data');

    // Logo
    let logo = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    worksheet.addImage(logo, 'A1:E5');
    worksheet.mergeCells('A1:E5');

    // Title
    let cell_title = worksheet.getCell('B7');
    let cell_title_range = this.daterange[0].toDateString() == this.daterange[1].toDateString() ?
      this.datePipe.transform(this.daterange[0], 'dd-MM-yyyy').toString() :
      this.datePipe.transform(this.daterange[0], 'dd-MM-yyyy').toString() + ' To ' + this.datePipe.transform(this.daterange[1], 'dd-MM-yyyy').toString();
    cell_title.value = 'Activity Statement: ' + cell_title_range;
    cell_title.alignment = { vertical: 'middle', horizontal: 'center' };
    cell_title.font = { bold: true };
    worksheet.mergeCells('B7:D7');

    // Statement ID
    let cell_statement_id = worksheet.getCell('I5');
    cell_statement_id.value = new Date().getFullYear().toString() + (new Date().getMonth() + 1).toString() + "-" + this.userId;
    cell_statement_id.font = { size: 16 };

    // Ajdust Amount
    let cell_amount = worksheet.getCell('I6');
    cell_amount.value = 'Adjust Amount';
    cell_amount.font = { size: 16 };

    // Empty row
    worksheet.addRow([]);

    // Columns Header
    worksheet.columns = this.header;
    let headerRow = worksheet.addRow({
      symbol: "Symbol",
      createtime: "Date/Time",
      volume: "Quantity",
      price: "T. Price",
      ai_status: "AI Status",
      fees: "Comm/Fee",
      extra_charge: "Third-Party Charge",
      basis: "Basis",
      RPL: "Realized P/L",
      wallet_name: "Wallet Name/Amount"
    });
    headerRow.height = 25;
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF2CC' },
        bgColor: { argb: 'FFF2CC' }
      }
      cell.border = { top: { style: 'medium', color: { argb: 'DEDEDE' } }, left: { style: 'medium', color: { argb: 'DEDEDE' } }, bottom: { style: 'medium', color: { argb: 'DEDEDE' } }, right: { style: 'medium', color: { argb: 'DEDEDE' } } }
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
    });

    // Text = "Stocks"
    worksheet.addRow({ symbol: "Stocks", wallet_name: "" })
      .eachCell({ includeEmpty: true }, (cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE699' },
          bgColor: { argb: 'FFE699' }
        }
      });


    // fetch grouped data
    let groupedHistory: Object = this.prepareData(this.data);

    if (!Object.keys(groupedHistory).length) {
      alert('No data found for given range.');
      return;
    }

    // iterate over the exchanges
    for (const currency in groupedHistory) {
      if (Object.prototype.hasOwnProperty.call(groupedHistory, currency)) {
        console.log('Getting rows for:', currency);

        let rows = groupedHistory[currency];

        // sort by symbol & id
        rows = rows.sort(function (a: any, b: any) {
          return a.symbol.localeCompare(b.symbol) || (a.id - b.id);
        })

        // add currency row
        worksheet.addRow({ symbol: currency, wallet_name: "" })
          .eachCell({ includeEmpty: true }, (cell, number) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFF2CC' },
              bgColor: { argb: 'FFF2CC' }
            }
          });

        // Main Data
        var totalFees = 0;
        var total_extra_charge = 0;
        var total_basis = 0;
        var total_RPL = 0;
        for (let i = 0; i < rows.length; i++) {        
          // determine position type - open/close
          rows[i].position_type = this.getPositionType(this.data, rows[i]);
          console.log("symbol:", rows[i].symbol, "type:", rows[i].position_type, "id:", rows[i].id);

          // calculate basis for MKT, AT orders 
          if(rows[i].action) {
            // update basis for BC
            if (i > 0 && (rows[i].position_type == "B/C" || rows[i-1].ai_status == "B/C")) 
            {
              
              // update basis
              // AT BC order
              if(rows[i].position_type == "B/C") {
                let allOrders = this.getSymbolHistory(this.data, rows[i].symbol).filter(row => row.action);
                let currentOrderIndex = allOrders.findIndex(order => order.id === rows[i].id);     
                let openPositionRow = this.mapRow(allOrders[currentOrderIndex-1]);
                console.log("Current Position BC. Open Position Row:", openPositionRow, 'Close Position Row:', rows[i]);
                rows[i].basis = this.calculateBasis(rows[i].volume, rows[i].price, rows[i].fees, rows[i].extra_charge, "B/C", openPositionRow.volume, openPositionRow.price);                
              } 
              // MKT BC order
              else {
                console.log("Current Position BC. Open Position Row:", rows[i-2], 'Close Position Row:', rows[i]);
                rows[i].basis = this.calculateBasis(rows[i].volume, rows[i].price, rows[i].fees, rows[i].extra_charge, "B/C", rows[i-2].volume, rows[i-2].price);
              }              
              
              rows[i].position_type = "B/C";
            } 
            else {
              // update basis
              rows[i].basis = this.calculateBasis(rows[i].volume, rows[i].price, rows[i].fees, rows[i].extra_charge, rows[i].position_type, null, null);
            }

            // calculate RPL
            // Auto trading fees logic
            // check for close position
            if(rows[i].position_type == "B/C" || rows[i].position_type == "S/C") {

              let open_basis;
              let close_basis = rows[i].basis;
              
              let allOrders = this.getSymbolHistory(this.data, rows[i].symbol).filter(row => row.action);
              let currentOrderIndex = allOrders.findIndex(order => order.id === rows[i].id);     
              let openPositionRow = this.mapRow(allOrders[currentOrderIndex-1]);            

              // the open position calculation may not exist in the report. need to calculate basis for this case
              if(rows.findIndex(row => row.id === openPositionRow.id) === -1) {
                // calculate basis for open position not avaijlable in the report rows
                open_basis = this.calculateBasis(openPositionRow.volume, openPositionRow.price, openPositionRow.fees, openPositionRow.extra_charge, openPositionRow.ai_status, null, null);
                console.log('Open Position Row not found in report. Calculated basis:', open_basis, 'Open Position Row:', openPositionRow);
              } else {
                let openPositionIndex = rows.findIndex(row => row.id === openPositionRow.id)
                open_basis = rows[openPositionIndex].basis;
                console.log('Open Position Row found in report. Open Position Row:', rows[openPositionIndex]);
              }
              
              // calculate RPL
              rows[i].RPL = close_basis + open_basis;

              // Auto trading logic
              // check if AT fees applied
              if(openPositionRow.order_type == "AT") {
                // check if fees applied  
                let atFees = this.calculateATFees(openPositionRow.price, rows[i].price, rows[i].volume);
                  console.log("Checking if need to add extra AT row. AT fees:", atFees, );
                  if(atFees < 0) {
                    rows[i].additional_at_row = true;
                    rows[i].additional_at_row_basis = atFees * 0.1;
                  }
              }
            }
          }

          // Add the main ROW 
          // highlight row for close_open position
          if(rows[i].position_type == "C/O") {
            worksheet.addRow(rows[i])
            .eachCell({ includeEmpty: true }, (cell, number) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFE699' },
                bgColor: { argb: 'FFE699' }
              }
            });
          } else {
            worksheet.addRow(rows[i]);
          }

          // Add extra AT fees row
          if(rows[i].additional_at_row) {
            let at_row = {
              symbol: rows[i].symbol,
              createtime: rows[i].createtime,
              ai_status: "Auto Trading",
              basis: rows[i].additional_at_row_basis,
              wallet_name: rows[i].wallet_name,              
            }

            // add row and highlight
            worksheet.addRow(at_row);
          }
          
          // Add symbol group total row
          // check for a different next symbol
          if (i == rows.length - 1 || rows[i].symbol != rows[i + 1].symbol) {
            let {fees , extra_charge, basis, RPL} = this.getTotalsBySymbol(rows, rows[i].symbol);
              
            // Add into totals
            totalFees += fees;
            total_extra_charge += extra_charge;
            total_basis += basis;
            total_RPL += RPL;

            // Total Row for symbols
            worksheet.addRow(
              { 
                symbol: "Total " + rows[i].symbol, 
                wallet_name: "", 
                fees: fees,                
                extra_charge: extra_charge, 
                basis: basis, 
                RPL: RPL
              })
              .eachCell({ includeEmpty: true }, (cell, number) => {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFF2CC' },
                  bgColor: { argb: 'FFF2CC' }
                }
              });
          } 
          
          // Final Report Total Row (all symbols)
          if (i == rows.length - 1) {
            worksheet.addRow(
              { 
                symbol: "Total", 
                wallet_name: "",
                fees: totalFees, 
                extra_charge: total_extra_charge, 
                basis: total_basis, 
                RPL: total_RPL,
              })
              .eachCell({ includeEmpty: true }, (cell, number) => {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFE699' },
                  bgColor: { argb: 'FFE699' }
                },
                  cell.font = {
                    bold: true
                  }
              });
          }
        }


        // Empty Rows
        for (let i = 0; i < 5; i++) { worksheet.addRow([]); }
      }
    }



    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Activity Statement - ' + cell_title_range + '.xlsx');
    })
  }

  prepareData(data: any) {
    let result = [];

    let fromDate = this.daterange[0];
    let toDate = this.daterange[1];

    // filter by date range
    // Daily Report
    if (fromDate.getTime() == toDate.getTime()) {
      data = data.filter((item: any) => {
        return new Date(item.createtime).toDateString() == fromDate.toDateString();
      });
    } else {
      data = data.filter((item: any) => {
        return new Date(item.createtime).getTime() >= fromDate.getTime() &&
          new Date(item.createtime).getTime() <= toDate.getTime();
      });
    }

    // sort by date - ASC
    data = data.sort(function (a: any, b: any) {
      return new Date(a.createtime).getTime() - new Date(b.createtime).getTime();
    })

    // model data
    for (var i = 0; i < data.length; i++) {

      // skip wallet Q
      if(data[i].wallet_name == "Q" || data[i].wallet_name == "q") {
        continue;  
      }

      let row = this.mapRow(data[i]);
    
      // push row
      result.push(row);
    }

    // group data by the exchange
    result = result.reduce(function (r, a) {
      let currency = "NA";

      if (a.exchange == "NYSE" || a.exchange == "NASDAQ") {
        currency = "USD";
      }

      if (a.exchange == "TSE") {
        currency = "CAD";
      }

      if (a.exchange == "SEHK") {
        currency = "HKD";
      }

      if (a.exchange == "SHA" || a.exchange == "SHE" || a.exchange == "SEHKNTL") {
        currency = "CNY";
      }

      r[currency] = r[currency] || [];
      r[currency].push(a);
      return r;
    }, Object.create(null));

    return result;
  }

  calculateFees = (exchange, price, quantity) => {
    let baseCapital, commission;

    baseCapital = price * quantity;

    if(exchange == "NYSE" || exchange == "NASDAQ") { 
      commission = ((0.008 * quantity > 1.5) ? (0.008 * quantity) : 1.5);
    } if(exchange == "TSE") {
      commission = ((0.015 * quantity > 1.5) ? (0.015 * quantity) : 1.5);
    } if(exchange == "SHE" || exchange == "SHA" || exchange == "SEHKNTL") {
      commission = ((0.001 * baseCapital > 20) ? 0.001 * baseCapital : 20);
    } if(exchange == "SEHK") {
      commission = ((0.001 * baseCapital > 25) ? 0.001 * baseCapital : 25);
    }

    return commission * -1;
  }

  calculateBasis = (volume, price, fees, third_party_charge, position, open_volume, open_price) => {
    if (position === "B/O" || position === "C/O" || position === "S/C") {
      return (price * volume - fees - third_party_charge) * -1;
    } 
    else if (position === "S/O") {
      return (price * volume + fees + third_party_charge);
    }
    else if (position === "B/C") {
      return -2 * open_volume * open_price - price * volume + fees + third_party_charge;
    }
    else {
      return 0;
    }
  }

  
  calculateATFees = (open_price, close_price, close_volume) => {
    return (close_price - open_price) * close_volume;
  }

  getPositionType = (history, currentOrder) => {
  
    // check for AT order and return the position type
    if(currentOrder.ai_status) {
      // check for B/C or S/C
      let allOrders = this.getSymbolHistory(history, currentOrder.symbol);
      
      let currentOrderIndex = allOrders.findIndex(order => order.id === currentOrder.id);     
      console.log(allOrders, 'order:', currentOrder.id, 'index:', currentOrderIndex);

      // get prev AT order
      let atOrders = allOrders.filter(order => order.order_type == "AT" && order.action && order.symbol == currentOrder.symbol);
      let prevATOrderIndex = atOrders.findIndex(order => order.id === currentOrder.id) - 1;
      console.log(atOrders, 'prev at order index:', prevATOrderIndex);

      // match consecutive B/O or S/O AT orders
      if(
        (currentOrder.ai_status == "B/O" || currentOrder.ai_status == "S/O") &&
        (prevATOrderIndex >= 0 && (atOrders[prevATOrderIndex].ai_status == "B/O" || atOrders[prevATOrderIndex].ai_status == "S/O") && currentOrder.ai_status != atOrders[prevATOrderIndex].ai_status)
      )
      {      
        console.log("matched consecutive BO SO");
        return currentOrder.ai_status == "B/O" ? "B/C" : "S/C";       
      }
      else {
        return currentOrder.ai_status
      }    
    }

    // determine position type for MKT order
    // Filter MKT order by symbol and sort asc
    var orders = history.filter(row => row.symbol === currentOrder.symbol && row.action && row.volume && row.id < currentOrder.id).sort((a, b) => {
      return (a.id - b.id); 
    });

    var netPosition = 0;

    for(var j = 0; j < orders.length; j++) {
      if(orders[j].action == "BUY") {
        netPosition = netPosition + orders[j].volume;
      }
      if(orders[j].action == "SELL") {
        netPosition = netPosition - orders[j].volume;
      }
    }

    // console.log("netPosition:", netPosition, "volume:", currentOrder.volume)
    if(netPosition == 0) {
      return currentOrder.action == "BUY" ? "B/O" : "S/O";
    } else {
      if(netPosition + currentOrder.volume == 0) {
        return currentOrder.action == "BUY" ? "B/C" : "S/C";
      }
      // close and open
      else if (Math.abs(netPosition) < Math.abs(currentOrder.volume)) {
        return "C/O"
      }
      else {
        return "B/O";
      }
    }
  }

  getSymbolHistory (history, symbol) {
    return history.filter(row => row.symbol === symbol).sort((a, b) => {
      return (a.id - b.id); 
    });
  }

  mapRow (data) {
    return {
      symbol: data.symbol,
      createtime: this.datePipe.transform(data.createtime, 'dd-MM-yyyy').toString(),
      volume: data.action == 'SELL' ? data.volume * -1 : data.volume,
      price: data.avg_fill_price,
      ai_status: data.ai_status,
      fees: this.calculateFees(data.exchange, data.avg_fill_price, data.volume),
      // NO extra charge for AI advice
      extra_charge: !data.action ? 0 : this.extra_charge,
      // -1.5 basis for AI advice 
      basis: (data.ai_status && !data.order_type) ? -1.5 : 0,
      RPL: 0,
      wallet_name: data.wallet_name,
      exchange: data.exchange,
      position_type: "",
      id: data.id,
      action: data.action,
      order_type: data.order_type,
      additional_at_row: false,
      additional_at_row_basis: 0
    }
  }

  getTotalsBySymbol  = (rows, symbol) => {
    // filter by symbol
    rows = rows.filter(row => row.symbol == symbol);

    return {
      fees: rows.map(row => row.fees).reduce((acc, fees) => acc + fees, 0),
      extra_charge: rows.map(row => row.extra_charge).reduce((acc, extra_charge) => acc + extra_charge, 0),
      // add basis + calculated AT fees basis 
      basis: rows.map(row => row.basis).reduce((acc, basis) => acc + basis, 0) + rows.map(row => row.additional_at_row_basis).reduce((acc, basis) => acc + basis, 0),
      RPL: rows.map(row => row.RPL).reduce((acc, RPL) => acc + RPL, 0)
    };
  }

}
