import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  userDetails;

  constructor(private activatedRoute: ActivatedRoute,
    private userService: UserService) {

  }

  ngOnInit() {
    const id = this.activatedRoute.snapshot.params['userId'];
    this.getUserDetails(id);
  }

  getUserDetails(userId: number) {
    this.userService.getUserDetails(userId).subscribe(data => {
      this.userDetails = data;
      console.log(this.userDetails);
    });
  }

}
