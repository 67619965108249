import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { UserService } from '../../../services/user.service' 

@Component({
  selector: 'app-pending-orders-action',
  templateUrl: './pending-orders-action.component.html',
  styleUrls: ['./pending-orders-action.component.css']
})
export class PendingOrdersActionComponent implements OnInit, ICellRendererAngularComp {
  
  public params: any;
  userId;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.params['userId'];
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  deleteOrder() {
    
    if(confirm("Are you sure to delete the order no: " + this.params.data.index + "?")) {
      this.userService.deletePendingOrders(this.userId, this.params.data.index).subscribe(res => {
        if(res.success) {
          alert('Successfully deleted the order!');
        } else {
          alert('Error while deleting the order!');
        }

        window.location.reload();
      })
    }
  }

}
