import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-wallet-action',
  templateUrl: './wallet-action.component.html',
  styleUrls: ['./wallet-action.component.css']
})
export class WalletActionComponent implements OnInit, ICellRendererAngularComp {
  public params: any;

  constructor(private route: Router, private userService: UserService) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  showBalance() {
    this.route.navigate(['balance/' + this.params.data.id + '/' + this.params.data.wallethash]);
  }

  ngOnInit() {
  }

  freezeWallet() {
    this.userService.freezeWallet(this.params.data.wallethash).subscribe(data => {
      if(data.success) {
        alert('Successfully freeze the wallet!');
        location.reload();
      } else {
        alert('Error while freezing the wallet!');
      }
    })
  }

  unfreezeWallet() {
    this.userService.unfreezeWallet(this.params.data.wallethash).subscribe(data => {
      if(data.success) {
        alert('Successfully activated the wallet!');
        location.reload();
      } else {
        alert('Error while activating the wallet!');
      }
    })
  }

}
