import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { DatePipe } from '@angular/common';

//AG_GRID
import { AgGridModule } from 'ag-grid-angular';

//NGX_MODAL
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

// Components 
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FundTransferDashboardComponent } from './components/fund-transfer-dashboard/fund-transfer-dashboard.component';
import { FundTransferActionComponent } from './components/fund-transfer-dashboard/action/fund-transfer-action.component'
import { NavbarComponent } from './components/navbar/navbar.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserListActionComponent } from './components/user-list/action/user-list-action.component';
import { UserWalletsComponent } from './components/user-wallets/user-wallets.component';
import { UserWalletsComponentNew } from './components/user-wallets-new/user-wallets-new.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { TradingHistoryComponent } from './components/trading-history/trading-history.component';
import { MonthlyTradingHistoryComponent } from './components/monthly-trading-history/monthly-trading-history.component';
import { WalletActionComponent } from './components/user-wallets/wallet-action/wallet-action.component';
import { BalanceComponent } from './components/user-wallets/balance/balance.component';
import { FundDetailsActionComponent } from './components/fund-transfer-dashboard/fund-details-action/fund-details-action.component';

import { AuthGuard } from './guards/auth.guard';
import { FundDetailsComponent } from './components/fund-transfer-dashboard/fund-details/fund-details.component';
import { ActionComponent } from './components/fund-transfer-dashboard/action/action.component';
import { AddAdminComponent } from './components/add-admin/add-admin.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ReportComponent } from './components/report/report.component';
import { MonthlyReportComponent } from './components/monthly-report/monthly-report.component';
import { GenerateReportComponent } from './components/generate-report/generate-report.component';
import { UserPendingOrdersComponent } from './components/user-pending-orders/user-pending-orders.component';
import { PendingOrdersActionComponent } from './components/user-pending-orders/action/pending-orders-action.component';
import { PaypalTransferComponent } from './components/paypal-transfer/paypal-transfer.component';
import { PaypalTranferActionComponent } from './components/paypal-transfer/action/paypal-tranfer-action.component';
import { UserFeaturesComponent } from './components/user-features/user-features.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'fundtransferdashboard', component: FundTransferDashboardComponent, canActivate: [AuthGuard] },
  { path: 'pptransfer', component: PaypalTransferComponent, canActivate: [AuthGuard] },
  { path: 'generate-report', component: GenerateReportComponent, canActivate: [AuthGuard] },
  { path: 'userlist', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'orders/:userId', component: UserPendingOrdersComponent, canActivate: [AuthGuard] },
  { path: 'account/addnew', component: AddAdminComponent, canActivate: [AuthGuard] },
  { path: 'account/pwd', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'account/email', component: ChangeEmailComponent, canActivate: [AuthGuard] },
  { path: 'userwallets/:username/:userId', component: UserWalletsComponent, canActivate: [AuthGuard] },
  { path: 'userwalletsnew/:username/:userId', component: UserWalletsComponentNew, canActivate: [AuthGuard] },
  { path: 'userprofile/:userId', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'balance/:walletId/:walletHash', component: BalanceComponent, canActivate: [AuthGuard] },
  { path: 'tradinghistory/:userId', component: TradingHistoryComponent, canActivate: [AuthGuard] },
  { path: 'monthlytradinghistory/:userId', component: MonthlyTradingHistoryComponent, canActivate: [AuthGuard] },
  { path: 'userfeatures/:userId', component: UserFeaturesComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    AgGridModule.withComponents([]),
    BrowserModule,
    FormsModule,
    HttpModule,
    RouterModule.forRoot(appRoutes),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NavbarComponent,
    FundTransferDashboardComponent,
    FundTransferActionComponent,
    UserListComponent,
    UserListActionComponent,
    UserWalletsComponent,
    UserWalletsComponentNew,
    UserProfileComponent,
    TradingHistoryComponent,
    MonthlyTradingHistoryComponent,
    WalletActionComponent,
    BalanceComponent,
    FundDetailsActionComponent,
    FundDetailsComponent,
    ActionComponent,
    AddAdminComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    MonthlyReportComponent,
    ReportComponent,
    GenerateReportComponent,
    UserPendingOrdersComponent,
    PendingOrdersActionComponent,
    PaypalTransferComponent,
    PaypalTranferActionComponent,
    UserFeaturesComponent
  ],
  entryComponents: [
    FundTransferActionComponent,
    UserListActionComponent,
    WalletActionComponent,
    FundDetailsActionComponent,
    FundDetailsComponent,
    PendingOrdersActionComponent,
    PaypalTranferActionComponent
  ],
  providers: [
    FundTransferActionComponent,
    FundDetailsComponent,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
