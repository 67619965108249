import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    URL = environment.apiURL + '/users';

    uploadURL = environment.apiURL;

    constructor(private http: Http, private authService: AuthService) { }

    saveReport(formData: FormData) {  // Accept FormData object
        let headers = new Headers();
        headers.append('Authorization', this.authService.loadToken());

        console.log('Headers:', headers)

        return this.http.post(this.uploadURL + '/upload', formData, { headers: headers })
            .pipe(
                map(res => res.json()),
                catchError(error => {
                    console.log('error:', error)
                    console.error('Error saving report:', error);
                    return throwError(error);
                })
            );
    }

    generatePDF(currentStatementPath: string, previousStatementPath: string, r_email: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());

        const body = JSON.stringify({
            currentStatementPath: currentStatementPath,
            previousStatementPath: previousStatementPath,
            r_email: r_email

        });

        return this.http.post(this.URL + '/generate-pdf', body, { headers: headers })
            .pipe(
                map(response => response.json()),
                catchError(error => {
                    console.error('Error generating PDF:', error);
                    return throwError(error);
                })
            );
    }

    updateWalletBalance(walletName: string, balance: number, userId: number, endDate: string, walletHash: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());

        const body = JSON.stringify({
            walletName: walletName,
            balance: balance,
            userId: userId,
            endDate: endDate,
            walletHash: walletHash
        });

        return this.http.post(this.URL + '/update-wallet-balance', body, { headers: headers })
            .pipe(
                map(res => res.json()),
                catchError(error => {
                    console.error('Error updating wallet balance:', error);
                    return throwError(error);
                })
            );
    }

    getWalletDetailsByUserId(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
    
        return this.http.get(this.URL + '/' + userId + '/wallet-details', { headers: headers })
            .pipe(
                map(res => res.json()),
                catchError(error => {
                    console.error('Error fetching wallet details:', error);
                    return throwError(error);
                })
            );
    }    

    generateWallet(currentStatementPath: string, previousStatementPath: string) {
        console.log('generate wallet need')
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());

        const body = JSON.stringify({
            currentStatementPath: currentStatementPath,
            previousStatementPath: previousStatementPath,
        });

        return this.http.post(this.URL + '/generate-wallet-balance', body, { headers: headers })
            .pipe(
                map(response => response.json()),
                catchError(error => {
                    console.error('Error generating Wallet Balance:', error);
                    return throwError(error);
                })
            );
    }

    getFundTransfersByUserId(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/' + userId + '/fundtransferbyId', { headers: headers })
            .pipe(map(res => res.json()));
    }


    getUserList() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/list', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getLatestHistoryForCurrentMonth() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/currentMonthHistory', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getUserWallet(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/' + userId + '/wallets', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getAllWallet() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL  + '/allwallets', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getUserDetails(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/' + userId + '/profile', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getUserHistory(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/' + userId + '/history', { headers: headers })
            .pipe(map(res => res.json()));
    }



    getUserMonthlyHistory(userId: number) {
        console.log('Get user monthly history 2:', userId)
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        console.log('Auth token', this.authService.loadToken())
        return this.http.get(this.URL + '/' + userId + '/monthlyhistory', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getHistory() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/all/history', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getWalletHistory(walletId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/wallets/' + walletId + '/history', { headers: headers })
            .pipe(map(res => res.json()));
    }

    fetchSymbolPosition(symbol, walletHash: string) {
        return new Promise((resolve, reject) => {
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Authorization', this.authService.loadToken());
            this.http.get(this.URL + '/position/' + symbol + '/' + walletHash, { headers: headers })
                .pipe(map(res => res.json()))
                .subscribe(res => {
                    if (res.success) {
                        resolve(res.balance);
                    } else {
                        reject(res.error);
                    }
                }, error => {
                    reject(error);
                });
        });
    }

    fetchCapital(walletHash: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/capital/' + walletHash, { headers: headers })
            .pipe(map(res => res.json()));
    }

    fetchRDSCapital(walletHash: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/USD/' + walletHash, { headers: headers })
            .pipe(map(res => res.json()));
    }

    freezeWallet(walletHash: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/wallets/' + walletHash + '/freeze', { headers: headers })
            .pipe(map(res => res.json()));
    }

    unfreezeWallet(walletHash: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/wallets/' + walletHash + '/unfreeze', { headers: headers })
            .pipe(map(res => res.json()));
    }

    activateAccount(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/' + userId + '/activate', { headers: headers })
            .pipe(map(res => res.json()));
    }

    deactivateAccount(userId: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(this.URL + '/' + userId + '/deactivate', { headers: headers })
            .pipe(map(res => res.json()));
    }

    getPendingOrders(accountId: number) {
        let headers = new Headers();
        console.log('Account id in user services', accountId)
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(environment.apiURL + '/orders/' + accountId, { headers: headers })
            .pipe(
                map(res => res.json()),
                tap(data => console.log('Response from getPending Orders', data)),
                catchError(error => {
                    console.error('Error fetching pending orders:', error);
                    return throwError(error);  // Proper error handling
                })

            );
    }

    getAllPendingOrders() {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.get(environment.apiURL + '/orders/', { headers: headers })
            .pipe(map(res => res.json()));
    }

    deletePendingOrders(accountId: number, orderIndex: number) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.authService.loadToken());
        return this.http.delete(environment.apiURL + '/orders/' + accountId + '/' + orderIndex, { headers: headers })
            .pipe(map(res => res.json()));
    }
}
