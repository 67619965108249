import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { WalletActionComponent } from './wallet-action/wallet-action.component';

@Component({
  selector: 'app-user-wallets',
  templateUrl: './user-wallets.component.html',
  styleUrls: ['./user-wallets.component.css']
})
export class UserWalletsComponent implements OnInit {

  rowData: any;
  columnDefs;
  gridApi;
  frameworkComponents;
  username: string;

  constructor(private activatedRoute: ActivatedRoute,
    private userService: UserService) {
    const id = this.activatedRoute.snapshot.params['userId'];
    this.username = this.activatedRoute.snapshot.params['username'];
    this.getUserWallets(id);
  }

  ngOnInit() {
    this.columnDefs = [
      // {
      //   headerName: "Id",
      //   field: 'id',
      //   width: 140,
      // },
      {
        headerName: "Wallet Name",
        field: 'wallet_name',
        width: 235,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Wallet Hash",
        field: 'wallethash',
        width: 400,
        filter: true,
        sortable: true,
        resizable: true
      },
      {
        headerName: "Status",
        field: 'islocked',
        width: 200,
        filter: true,
        sortable: true,
        resizable: true,
        valueFormatter: function (params) {
          return params.value === true ? 'LOCKED' : 'ACTIVE';
        },
      },
      {
        headerName: "Action",
        width: 300,
        cellRenderer: "actionComponent",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: false,
      },
    ];
    this.frameworkComponents = {
      actionComponent: WalletActionComponent
    };
  }

  getUserWallets(userId: number) {
    this.userService.getUserWallet(userId).subscribe(data => {
      this.rowData = data;
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    //this.gridApi.sizeColumnsToFit();
  }

}
